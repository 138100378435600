ul {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.Nav_Bar {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.Nav_Bar .Nav_Wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.Nav_Bar .Nav_Wrapper .Right_Side,
.Nav_Bar .Nav_Wrapper .Left_Side {
  display: flex;
  gap: 20px;
}
.Nav_Bar .Nav_Wrapper .Right_Side {
  gap: 0px;
}
.Nav_Bar .Nav_Wrapper .Right_Side .Logo {
  margin: auto;
  height: 25px;
  width: 25px;
}
.Nav_Bar .Nav_Wrapper .Right_Side .Dropdown_Container {
  display: flex;
  margin: auto;
  margin-left: 0px;
  gap: 30px;
}
.Nav_Bar .Nav_Wrapper .Right_Side .Dropdown_Container .Dropdown_Item {
  display: flex;
  margin: auto;
  align-items: center;
  gap: 2px;
}
.Nav_Bar .Nav_Wrapper .Right_Side .Dropdown_Container .Dropdown_Item .Dropdown_Link {
  color: black;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 500;
  margin: auto;
}
.Nav_Bar .Nav_Wrapper .Left_Side {
  align-items: center;
}
.Nav_Bar .Nav_Wrapper .Left_Side .Input_Wrapper {
  border: none;
  border-radius: 20px;
  background-color: rgb(245, 244, 242);
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  gap: 10px;
  margin: auto;
  width: auto;
  height: 40px;
}
.Nav_Bar .Nav_Wrapper .Left_Side .Input_Wrapper .Nav_Input {
  background-color: rgb(245, 244, 242);
  outline: none;
  border: none;
}
.Nav_Bar .Nav_Wrapper .Left_Side .Login_Link {
  color: black;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin: auto;
}
.Nav_Bar .Nav_Wrapper .Left_Side .Patreon_Button {
  border: 2px solid #ff424d;
  border-radius: 30px;
  background-color: #ff424d;
  display: flex;
  padding: 15px;
  gap: 10px;
  cursor: pointer;
}
.Nav_Bar .Nav_Wrapper .Left_Side .Patreon_Button .Button_Text {
  color: white;
  font-family: sans-serif;
  font-size: 17px;
  font-weight: 400;
  margin: auto;
}
.Nav_Bar .Nav_Wrapper .Left_Side .Patreon_Button:hover {
  background-color: white;
}
.Nav_Bar .Nav_Wrapper .Left_Side .Patreon_Button:hover .Button_Text {
  color: #ff424d;
}
.Nav_Bar .Nav_Wrapper .Left_Side .Icon_Wrapper {
  display: none;
}
@media only screen and (max-width: 360px) {
  .Nav_Bar .Nav_Wrapper .Left_Side .Patreon_Button {
    padding: 10px;
  }
  .Nav_Bar .Nav_Wrapper .Left_Side .Patreon_Button .Button_Text {
    font-size: 12px;
  }
}
.Nav_Bar .Responsive_Input_Wraper {
  border: none;
  border-radius: 20px;
  background-color: rgb(245, 244, 242);
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  gap: 10px;
  margin: auto;
  margin-top: 10px;
  width: 100%;
  height: 40px;
  display: none;
}
.Nav_Bar .Responsive_Input_Wraper .Responsive_Input {
  background-color: rgb(245, 244, 242);
  outline: none;
  border: none;
}
@media only screen and (max-width: 1025px) {
  .Nav_Bar .Responsive_Input_Wraper {
    display: flex;
  }
  .Nav_Bar .Nav_Wrapper .Right_Side .Dropdown_Container {
    display: none;
  }
  .Nav_Bar .Nav_Wrapper .Left_Side .Input_Wrapper {
    display: none;
  }
  .Nav_Bar .Nav_Wrapper .Left_Side .Icon_Wrapper {
    display: flex;
  }
}/*# sourceMappingURL=NavBar.module.css.map */